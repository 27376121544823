import GetUserDetailsBuilder from "src/builders/GetUserDetails";
import {
    CreateOrUpdateQueuesFormData,
    CreateOrUpdateQueuesToUserMapFormData,
    QueueUserOperationType
} from "src/models/ServiceRequests/CaseAuthorityServiceRequests";
import CreateOrUpdateQueuesBuilder from "src/builders/CreateOrUpdateQueuesBuilder";
import {BulkFileUploadExpectedHeaders} from "src/configurations/InputElementConfigs";
import {ENTITY_TYPE} from "src/constants/enums";
import {FileUtils} from "src/utils/FileUtils";
import Papa from "papaparse";
import {BulkExceptionDetail} from "src/models/ExceptionDetail";
import {CreateOrUpdateQueuesResponse} from "src/models/ServiceResponses/CaseAuthorityServiceResponses";
import {EMPLOYEE_ID_SEPARATOR} from "src/constants/DisplayConstants";
import GetQueuesBuilder from "src/builders/GetQueuesBuilder";

export const fetchUserIdsFromEmployeeIds = async (domainId: string, employeeIds: string[] ) => {
    const userIds : string[] = [];
    for (const employeeId of employeeIds){
        const response = await GetUserDetailsBuilder.getUserDetails(domainId, employeeId, undefined);
        userIds.push(response.user.userId);
    }
    return userIds;
}

export const processBulkQueues = async (domainId: string, userAlias: string, queueList: CreateOrUpdateQueuesFormData[]) => {
    const successResponses: string[] = [];
    const failureResponses: BulkExceptionDetail[] = [];
    for (const queueDetails of queueList) {
        try {
            let response: CreateOrUpdateQueuesResponse;
            if (queueDetails.queueId) {
                const queueDetailsById = await
                    GetQueuesBuilder.getQueues(domainId, queueDetails.queueId);
                if (queueDetailsById.queues.length > 0) {
                    throw Error(`Queue with ID ${queueDetails.queueId} already exists`);
                }
            }
            response = await CreateOrUpdateQueuesBuilder.createQueue(domainId, userAlias, queueDetails);
            successResponses.push(response.queueId);
        } catch (error) {
            failureResponses.push({
                requestDetails: queueDetails,
                exceptionDetail: error
            });
        }
    }
    FileUtils.downloadSuccessFailureLog('BulkCreateOrUpdateQueues', successResponses, failureResponses);
}

export const processBulkQueueToUserMapping = async (domainId: string, userAlias: string,
                                           userOperationType: QueueUserOperationType,
                                           queueDetailsList: CreateOrUpdateQueuesToUserMapFormData[]) => {
    const successResponses: string[] = [];
    const failureResponses: BulkExceptionDetail[] = [];
    for (const queueDetails of queueDetailsList) {
        try {
            const queueDetailsById = await GetQueuesBuilder.getQueues(domainId, queueDetails.queueId);
            const queueDetailById = queueDetailsById.queues[0];
            queueDetails.name = queueDetailById.name;
            queueDetails.description = queueDetailById.description;
            queueDetails.additionalAttributes = queueDetailById.additionalAttributes;
            const employeeIds = queueDetails.employeeIds.split(EMPLOYEE_ID_SEPARATOR);
            const userIds = await fetchUserIdsFromEmployeeIds(domainId, employeeIds);
            for (const userId of userIds) {
                try {
                    queueDetails.userOperation = {type: userOperationType, userIds: [userId]}
                    const response = await CreateOrUpdateQueuesBuilder.editQueue(domainId, userAlias, queueDetails);
                    successResponses.push(response.queueId)
                } catch (error) {
                    failureResponses.push({
                        requestDetails: queueDetails,
                        exceptionDetail: error
                    });
                }
            }
        } catch (error) {
            failureResponses.push({
                requestDetails: queueDetails,
                exceptionDetail: error
            });
        }
    }
    FileUtils.downloadSuccessFailureLog('BulkCreateOrUpdateQueues', successResponses, failureResponses);
}

export const processQueueFiles = (domainId: string, userAlias: string, userOperationType: QueueUserOperationType): ((files: File[]) => void) => {
    return async (files: File[]) => {
        Papa.parse(files[0], {
            header: true,
            skipEmptyLines: true,
            complete: async function (results) {
                const successResponses: string[] = [];
                const failureResponses: BulkExceptionDetail[] = [];
                for (const queueDetails of results.data) {
                    try {
                        const employeeIds = queueDetails.employeeIds.split(';');
                        const userIds = await fetchUserIdsFromEmployeeIds(domainId, employeeIds);
                        const queueDetailsById = await GetQueuesBuilder.getQueues(domainId, queueDetails.queueId);
                        const queueDetailById = queueDetailsById.queues[0];
                        queueDetails.name = queueDetailById.name;
                        queueDetails.description = queueDetailById.description;
                        queueDetails.additionalAttributes = queueDetailById.additionalAttributes;
                        for (const userId of userIds) {
                            try {
                                queueDetails['userOperation'] = {type: userOperationType, userIds: [userId]}
                                const response = await CreateOrUpdateQueuesBuilder.editQueue(domainId, userAlias, queueDetails);
                                successResponses.push(response.queueId)
                            } catch (e) {
                                failureResponses.push({
                                    requestDetails: queueDetails,
                                    exceptionDetail: e
                                });
                            }
                        }
                    } catch (error) {
                        failureResponses.push({
                            requestDetails: queueDetails,
                            exceptionDetail: error
                        });
                    }
                }
                FileUtils.downloadSuccessFailureLog('BulkCreateOrUpdateQueues', successResponses, failureResponses);
            }
        });
    }
}


export const downloadQueuesFileTemplate = () => {
    const headers = BulkFileUploadExpectedHeaders[ENTITY_TYPE.QUEUE_TO_USER_MAPPINGS];
    const fileName = 'create_or_update_users_to_queues_template.csv';
    FileUtils.downloadCsvWithHeader({headers, fileName})
}